import UserApi from "@/generatedapiclients/src/api/UserApi.js";
import AdminAuthApi from "@/generatedapiclients/src/api/AdminAuthApi.js";

export default class CaptchaService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._userApi = new UserApi();
    this._adminAuthApi = new AdminAuthApi();
  }

  async verifyCaptcha(token) {
    const response = await this._userApi.v1TenantTenantIdUserCaptchaVerifyPost(
      this._tenantId,
      { token }
    );
    return response.captcha;
  }

  async adminVerifyCaptcha(token) {
    const response = await this._adminAuthApi.v1TenantTenantIdAdminAuthCaptchaVerifyPost(
      this._tenantId,
      { token }
    );
    return response.captcha;
  }
}
