<template>
  <div class="container">
    <div class="mx-auto loginContent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        > CREATE ACCOUNT
      </div>
      <div class="card text-center">
        <div class="card-body">
          <h2 class="text-primary">Create a New Account</h2>
          <p>
            If you already created an account, please click here to
            <a
              class="text-primary"
              href="javascript:void(0);"
              @click="$router.push('/login').catch(() => {})"
              >login</a
            >
            instead.
          </p>
          <hr />
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.createAccountForm"
          />
          <ValidationObserver ref="createAccountForm">
            <form @submit.prevent="onSubmit">
              <h3>Account Information</h3>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|min:8|username|max:20"
                    name="Username"
                    id="registerUsername"
                    v-model="registerUsername"
                    placeholder="Enter your desired username"
                    vid="uname"
                  />
                  <div class="text-center my-2">
                    <div v-if="loadingUsername" class="my-3">
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                    <div v-if="!invalidUsername" class="mt-2 text-success">
                      Username is available!
                    </div>
                    <div
                      v-else-if="usernameChecked && !usernameAvailable"
                      class="mt-2 text-danger"
                    >
                      Username is not available!
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|email|max:256"
                    name="Email Address"
                    onpaste="false"
                    id="registerEmail1"
                    type="email"
                    vid="email"
                    v-model="registerEmail1"
                    placeholder="Enter your email address"
                  />
                  <div class="text-center my-2">
                    <div v-if="loadingEmail" class="my-3">
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                    <div
                      v-if="emailChecked && !emailAvailable"
                      class="mt-2 text-danger"
                    >
                      Email is already in use! Please try recovering your
                      username on the
                      <a
                        class="text-primary"
                        @click="$router.push('/').catch(() => {})"
                        >forgot login</a
                      >
                      page.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|email|confirmed:email"
                    name="Confirm Email Address"
                    onpaste="false"
                    id="registerEmail2"
                    type="email"
                    v-model="registerEmail2"
                    placeholder="Confirm your email address"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <h3>Personal Information</h3>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <label for="profilePrefix">Prefix</label>
                  <select
                    class="form-control form-control-lg"
                    id="profilePrefix"
                    v-model="profilePrefix"
                    :disabled="invalidUsername || invalidEmail"
                  >
                    <option
                      v-for="(prefix, index) in prefixes"
                      :key="index"
                      :value="prefix"
                    >
                      {{ prefix }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|customerName|max:30"
                    name="First Name"
                    id="profileFirstName"
                    placeholder="Enter your first name"
                    v-model="profileFirstName"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="customerName|max:25"
                    name="Middle Name"
                    id="profileMiddleName"
                    placeholder="Enter your middle name"
                    v-model="profileMiddleName"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|customerName|max:30|min:2"
                    name="Last Name"
                    id="profileLastName"
                    placeholder="Enter your last name"
                    v-model="profileLastName"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <label for="profileSuffix">Suffix</label>
                  <select
                    class="form-control form-control-lg"
                    id="profileSuffix"
                    v-model="profileSuffix"
                    :disabled="invalidUsername || invalidEmail"
                  >
                    <option
                      v-for="(suffix, index) in suffixes"
                      :key="index"
                      :value="suffix"
                    >
                      {{ suffix }}
                    </option>
                  </select>
                </div>
              </div>
              <h3>Address Information</h3>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <ValidationProvider
                    rules="required"
                    name="Country"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="profileCountry"
                    >
                      Country
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="profileCountry"
                      v-model="profileCountry"
                      v-bind="ariaInput"
                      :disabled="invalidUsername || invalidEmail"
                    >
                      <option
                        v-for="(country, index) in countries"
                        :key="index"
                        :value="country.abbreviation"
                      >
                        {{ getCountryName(country.abbreviation) }}
                      </option>
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|address|max:70"
                    name="Address 1"
                    id="profileAddress1"
                    placeholder="Enter your street address"
                    v-model="profileAddress1"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="address|max:70"
                    name="Address 2"
                    id="profileAddress2"
                    placeholder="Enter your street address, cont."
                    v-model="profileAddress2"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|city|max:58"
                    name="City"
                    id="profileCity"
                    placeholder="Enter your city"
                    v-model="profileCity"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|city|max:20"
                    name="State"
                    id="profileState"
                    placeholder="Enter your State"
                    v-model="profileState"
                    :disabled="invalidUsername || invalidEmail"
                    v-if="isForeignAddress"
                  />
                  <ValidationProvider
                    rules="required"
                    name="State"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                    v-else
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="profileState"
                    >
                      State
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="profileState"
                      v-model="profileState"
                      v-bind="ariaInput"
                      :disabled="invalidUsername || invalidEmail"
                    >
                      <option
                        v-for="(state, index) in states"
                        :key="index"
                        :value="state.abbreviation"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    :rules="
                      isForeignAddress ? 'required' : 'required|min:5|zipCode'
                    "
                    :name="isForeignAddress ? 'Postal Code' : 'Zip Code'"
                    id="profileZipCode"
                    placeholder="Enter your zip code"
                    v-model="profileZipCode"
                    :mask="isForeignAddress ? '' : '#####-####'"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <h3>Contact Information</h3>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|maskNumMin:10"
                    name="Phone"
                    id="profilePhone"
                    placeholder="Enter your phone number"
                    v-model="profilePhone"
                    mask="(###) ###-####"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="maskNumMin:10"
                    name="Alternate Phone"
                    id="profileAlternatePhone"
                    placeholder="Enter your alternate phone number"
                    v-model="profileAlternatePhone"
                    mask="(###) ###-####"
                    :disabled="invalidUsername || invalidEmail"
                  />
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="invalidUsername || invalidEmail"
              >
                CREATE ACCOUNT
              </button>
              <p class="mt-3">
                <span style="color: red">*</span> Indicates a required field
              </p>
              <p class="text-muted">
                This site is protected by reCAPTCHA and the Google
                <a
                  class="text-primary"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  >Privacy Policy</a
                >
                and
                <a
                  class="text-primary"
                  href="https://policies.google.com/terms"
                  target="_blank"
                  >Terms of Service</a
                >
                apply.
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import ProfileService from "@/services/ProfileService.js";
import CaptchaService from "@/services/CaptchaService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import states from "@/store/modules/states.js";
import countries from "@/store/modules/countries.js";

export default {
  name: "CreateAccount",
  title: "Create Account",
  computed: {
    states() {
      return states;
    },
    countries() {
      return countries;
    },
    invalidUsername() {
      return !(this.usernameAvailable && this.usernameChecked);
    },
    invalidEmail() {
      return !(this.emailAvailable && this.emailChecked);
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    isForeignAddress() {
      return this.profileCountry !== "US";
    }
  },
  data() {
    return {
      registerUsername: null,
      registerEmail1: null,
      registerEmail2: null,
      profilePrefix: null,
      profileFirstName: null,
      profileMiddleName: null,
      profileLastName: null,
      profileSuffix: null,
      profileCountry: "US",
      profileAddress1: null,
      profileAddress2: null,
      profileCity: null,
      profileState: null,
      profileZipCode: null,
      profilePhone: null,
      profileAlternatePhone: null,
      prefixes: ["Mr", "Ms", "Mrs", "Dr", "Sir"],
      suffixes: ["Jr", "Sr", "III", "Esq", "MD", "PhD"],
      errors: [],
      usernameAvailable: false,
      usernameChecked: false,
      loadingUsername: false,
      usernameTimeout: null,
      emailAvailable: false,
      emailChecked: false,
      loadingEmail: false,
      emailTimeout: null
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert
  },
  watch: {
    registerUsername() {
      clearTimeout(this.usernameTimeout);
      var self = this;
      this.usernameTimeout = setTimeout(() => {
        self.checkUsername(self.registerUsername);
      }, 1000);
    },
    registerEmail1() {
      clearTimeout(this.emailTimeout);
      var self = this;
      this.emailTimeout = setTimeout(() => {
        self.checkEmail(self.registerEmail1);
      }, 1000);
    }
  },
  methods: {
    getCountryName(abbv) {
      return this.countries.find(x => x.abbreviation === abbv).name;
    },
    async checkUsername(username) {
      if (
        username.match(/^[A-Za-z0-9-_=.]+$/) &&
        username.length > 7 &&
        username.length < 21
      ) {
        const profileService = new ProfileService(this.tenantId);
        this.loadingUsername = true;
        this.usernameAvailable = false;
        this.usernameChecked = false;
        const response = await profileService.checkUsernameAvailability(
          username
        );
        this.usernameAvailable =
          response.statusCode === "Success" && response.data === true;
        this.usernameChecked = true;
        this.loadingUsername = false;
      } else {
        this.usernameAvailable = this.usernameChecked = false;
      }
    },
    async checkEmail(email) {
      if (email.match(/\S+@\S+\.\S+/) && email.length < 257) {
        const profileService = new ProfileService(this.tenantId);
        this.loadingEmail = true;
        this.emailAvailable = false;
        this.emailChecked = false;
        const response = await profileService.checkEmailAvailability(email);
        this.emailAvailable =
          response.statusCode === "Success" && response.data === true;
        this.emailChecked = true;
        this.loadingEmail = false;
      } else {
        this.emailAvailable = this.emailChecked = false;
      }
    },
    async onSubmit() {
      const customer = {
        prefixName: this.profilePrefix,
        firstName: this.profileFirstName,
        middleName: this.profileMiddleName,
        lastName: this.profileLastName,
        suffixName: this.profileSuffix,
        email: this.registerEmail1,
        primaryPhoneNumber: this.profilePhone,
        alternatePhoneNumber: this.profileAlternatePhone,
        username: this.registerUsername,
        street1: this.profileAddress1,
        street2: this.profileAddress2,
        city: this.profileCity,
        state: this.profileState,
        zipCode: this.profileZipCode,
        country: this.profileCountry,
        isPrimary: true
      };
      this.$refs.createAccountForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.createAccountForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.createAccountForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha("login");
            const captchaService = new CaptchaService(this.tenantId);
            const verified = await captchaService.verifyCaptcha(token);
            if (verified && verified.success === true && verified.score > 0.6) {
              const profileService = new ProfileService(this.tenantId);
              const response = await profileService.createCustomerProfile(
                customer
              );
              if (response.statusCode == "Success") {
                const customerProfile = response.data;
                this.$store.commit("auth/setLoading", false);
                this.$router
                  .push(
                    "/account-activation/" + customerProfile.user.externalUserId
                  )
                  .catch(() => {});
              } else {
                this.$store.commit("alert/setErrorAlert", {
                  type: "alert-danger",
                  message:
                    "Account creation failed. This could be a duplicate account. Try logging in if you already have an account.",
                  layer: "public"
                });
              }
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Captcha was not verified",
                layer: "public"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    }
  }
};
</script>
